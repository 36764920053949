import * as React from "react"
import Plx from 'react-plx';
import Clouds from "../images/2.0/clouds.png";

const isBrowser = typeof window !== "undefined";
// markup
const RockLetters = (props) => {

  const lettersRef = React.useRef(null);

  let cloudsData = [
    {
      start: props.start,
      end: props.start + ((lettersRef.current?lettersRef.current.clientHeight:0)*2),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 20,
          property: 'left',
          unit:'vw'
        },
      ],
    }
  ];

  React.useEffect(() => {
    props.total((lettersRef.current?lettersRef.current.clientHeight:0));
    return () => {
    }

  });

  return (

    <div ref={lettersRef} className="rockletters">
      <Plx tagName="img" src={Clouds} parallaxData={cloudsData} />
    </div>
  )
}

export default RockLetters;
