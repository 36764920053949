import * as React from "react"
import Plx from 'react-plx';
import RockBg from "../../images/2.0/rock-bg.png";
import RockA from "../../images/2.0/rock-1.png";
import RockB from "../../images/2.0/rock-6.png";
import RockC from "../../images/2.0/rock-3.png";
import RockD from "../../images/2.0/rock-4.png";
import RockE from "../../images/2.0/rock-5.png";

const isBrowser = typeof window !== "undefined";

// markup
const Rock = (props) => {

  const rockRef = React.useRef(null);

  let rockDataA = [
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: (props.orientation=='portrait'?0:-20),
          endValue: (props.orientation=='portrait'?0:10),
          property: 'top',
          unit:'vh'
        },
      ],
    }
  ];
  let rockDataB = [
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 30,
          property: 'top',
          unit:'vh'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: -10,
          endValue: -20,
          property: 'left',
          unit:'vw'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: .7,
          endValue: 1,
          property: 'scale'
        },
      ],
    }
  ];
  let rockDataC = [
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 10,
          endValue: -20,
          property: 'top',
          unit:'vh'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 10,
          property: 'left',
          unit:'vw'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: .7,
          property: 'scale'
        },
      ],
    }
  ];
  let rockDataD = [
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 10,
          endValue: -20,
          property: 'top',
          unit:'vh'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 10,
          property: 'left',
          unit:'vw'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: .7,
          property: 'scale'
        },
      ],
    }
  ];
  let rockDataE = [
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: (props.orientation=='portrait'?0:15),
          endValue: (props.orientation=='portrait'?0:-20),
          property: 'top',
          unit:'vh'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 10,
          property: 'left',
          unit:'vw'
        },
      ],
    },
    {
      start: props.start,
      end: props.start + (props.height*1.5),
      easing:[0.42, 0.0, 1.00, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: .7,
          property: 'scale'
        },
      ],
    }
  ];

  React.useEffect(() => {
    props.total((rockRef.current?rockRef.current.clientHeight:0));
    return () => {
    }
  });

  return (

    <div ref={rockRef} className="rock_bg">
      <div className="rock" style={{backgroundImage:`url(${RockBg})`}}>
        <Plx
          tagName="img"
          src={RockA}
          className='rock_a'
          parallaxData={rockDataA}
        />
        <Plx
          tagName="img"
          src={RockB}
          className='rock_b'
          parallaxData={rockDataB}
        />
        <Plx
          tagName="img"
          src={RockC}
          className='rock_c'
          parallaxData={rockDataC}
        />
        <Plx
          tagName="img"
          src={RockD}
          className='rock_d'
          parallaxData={rockDataD}
        />
        <Plx
          tagName="img"
          src={RockE}
          className='rock_e'
          parallaxData={rockDataE}
        />
      </div>
    </div>
  )
}

export default Rock;
