import * as React from "react";
import Plx from "react-plx";
import GenImage from "../images/2.0/generation.jpg";

const Generation = (props) => {
  const generationRef = React.useRef(null);

  let bData = [
    {
      start: props.start,
      end: props.start + props.height,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: props.orientation == "portrait" ? 0 : 30,
          endValue: props.orientation == "portrait" ? 0 : 10,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(generationRef.current ? generationRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={generationRef} className="generation">
      <Plx tagName="img" src={GenImage} alt="Generation" parallaxData={bData} />
    </div>
  );
};

export default Generation;
