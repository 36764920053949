import * as React from "react";
import LinkedIn from "../images/2.0/linkedin.svg";

// markup
const Team = (props) => {
  const teamRef = React.useRef(null);

  React.useEffect(() => {
    props.total(teamRef.current ? teamRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={teamRef} className="team">
      
    </div>
  );
};

export default Team;
