import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/genesis.css";
import Icons from "../components/icons.js";
import Rock from "../components/genesis/rock.js";
import RockLetters from "../components/rockletters.js";
import CultureRain from "../components/culturerain.js";
import RoadMapText from "../components/roadmaptext.js";
import Generation from "../components/generation.js";
import Team from "../components/team.js";
import Future from "../components/planContainer.js";
import Footer from "../components/footer.js";
import About from "../components/about.js";
import Join from "../components/join.js";
import HomeSVG from "../images/2.0/home.svg";

var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

const isBrowser = typeof window !== "undefined";

// markup
const AboutPage = () => {

  const [hasScrolled, setHasScrolled] = React.useState(false);

  const [orientation, setOrientation] = React.useState("landscape");

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = React.useState(
    isBrowser ? window.innerHeight : 0
  );

  const [footerVisible, setFooterVisible] = React.useState(false);

  const [aboutHeight, setAboutHeight] = React.useState(0);
  const [rockHeight, setRockHeight] = React.useState(0);
  const [rockLettersHeight, setRockLettersHeight] = React.useState(0);

  const [roadmapHeight, setRoadmapHeight] = React.useState(0);
  const [futureHeight, setFutureHeight] = React.useState(0);
  const [generationHeight, setGenerationHeight] = React.useState(0);
  const [teamHeight, setTeamHeight] = React.useState(0);
  const [cultureHeight, setCultureHeight] = React.useState(0);
  const [joinHeight, setJoinHeight] = React.useState(0);
  const [lettersHeight, setLettersHeight] = React.useState(0);

  const boxRef = React.useRef(null);

  const updateWidthAndHeight = () => {
    setWidth(isBrowser ? window.innerWidth : 0);
    setHeight(isBrowser ? window.innerHeight : 0);
  };

  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };

  React.useEffect(() => {
    setScreenOrientation();
    updateWidthAndHeight();
    if (isBrowser) window.addEventListener("resize", updateWidthAndHeight);
    if (isBrowser)
      window.addEventListener("orientationchange", setScreenOrientation);
    return () => {
      if (isBrowser) window.removeEventListener("resize", updateWidthAndHeight);
      if (isBrowser)
        window.removeEventListener("orientationchange", setScreenOrientation);
    };
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABOUT | CULT&amp;RAIN™</title>
        <link rel="canonical" href="https://www.cultandrain.com/about" />
        <meta
          name="description"
          content="Luxury fashion born from Web3, merging NFTs with exclusive physical redeemables."
        />
      </Helmet>
      {isBrowser && (
        <div className="genesisPage">
          <div className="wrapper">
            <div>
              <a href="/" className="homebtn"><img src={HomeSVG} /></a>
              <Icons
                footerVisible={footerVisible}
                mintVisible={false}
                page="about"
              />
            </div>

            <div
              className="page"
            >
              <div>
                <RockLetters
                  start={0}
                  height={height}
                  total={setRockLettersHeight}
                />
                <About
                  start={0}
                  height={height}
                  total={setAboutHeight}
                  orientation={orientation}
                />
                <CultureRain
                  start={
                    aboutHeight
                  }
                  height={height}
                  total={setCultureHeight}
                  orientation={orientation}
                />
                <Team start={aboutHeight+cultureHeight} height={height} total={setTeamHeight} />
                <Rock
                  start={0}
                  height={aboutHeight+cultureHeight+teamHeight}
                  total={setRockHeight}
                  orientation={orientation}
                />
                <Generation start={aboutHeight+cultureHeight+teamHeight+rockHeight} height={height} total={setGenerationHeight} orientation={orientation} />
                
                {false&&<RoadMapText
                  start={
                    aboutHeight+cultureHeight+teamHeight+rockHeight+generationHeight
                  }
                  height={height}
                  total={setRoadmapHeight}
                />}

                {false&&<Future start={aboutHeight+rockLettersHeight+cultureHeight+teamHeight+rockHeight+generationHeight+roadmapHeight} height={height} total={setFutureHeight} />}

                <Join start={aboutHeight+rockLettersHeight+cultureHeight+teamHeight+rockHeight+generationHeight+roadmapHeight+futureHeight} height={height} total={setJoinHeight} />
                <Footer
                  start={
                    aboutHeight+rockLettersHeight+cultureHeight+teamHeight+rockHeight+generationHeight+roadmapHeight+futureHeight+joinHeight
                  }
                  height={height}
                  setFooterVisible={setFooterVisible}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "none" }}
        name="Join"
        method="POST"
        data-netlify="true"
      >
        <input type="email" name="email" />
      </form>
    </main>
  );
};

export default AboutPage;
